
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { Modal } from "bootstrap";
import { number } from "yup/lib/locale";
import Products from "@/views/apps/product/Products.vue";
import {ActionsRe} from "@/store/enums/StoreResearchEnums";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "add-product-price-modal",
  components: {
    
    },
    methods: {
      onChange() {
      if (this.formData.company_select == "") {
        this.formData.nob = "";
        this.formData.contact_select = "";
        this.formData.contact_no = "";
        this.formData.email_select = "";       
        
      }
    },
    onPlantChange() {
      if (this.formData.branch_select == "") {
        this.formData.city_select = "";
        this.formData.state_select = "";
        this.formData.pincode_select = "";
        
      }
    },
     onContactChange() {
      if (this.formData.contact_select == "") {
        this.formData.contact_no = "";
        this.formData.email_select = "";
        
      }
    },
  },

   
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const addPriceModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const router = useRouter(); 
    const id1data =ref("");
    const formData = ref({
      name: "",     
      nob: "", 
      branch_select: "",
      freq_select: "",
      currency_select: "",
      city_select: "",
      state_select : "",
      pincode_select : "",
      category_select : "",
      incoterm_select: "",
      company_select: "",
      contact_select: "",
      contact_no: "",
      email_select: "",
      specific_select: "",
    });

    onMounted( async () => {
      setCurrentPageBreadcrumbs("Add Price Template", ['Price Template']);
      await setIncotermData()
      await setContactData(contact.value)
      await setPlantData(branch.value)
      await setCurrencyData()
    });
    
    const rules = ref({
      // name: [
      //   {
      //     required: true,
      //     message: "Name is required",
      //     trigger: "change",  
             
      //   },  
      // ],   
      // city_select: [
      //   {
      //     required: true,
      //     message: "City is required",
      //     trigger: "change",  
      //   },  
      // ],   
      // parent_select: [
      //   {
      //     required: true,
      //     trigger: "change",  
      //   }
      // ],
      // category_select: [
      //   {
      //     required: true,
      //     message: "Category is required",
      //     trigger: "change",  
      //   },  
      // ],  
      // value_select: [
      //   {
      //     required: true,
      //     message: "Currency is required",
      //     trigger: "change",  
      //   },  
      // ], 
      // incoterm_select: [
      //   {
      //     required: true,
      //     message: "Incoterm is required",
      //     trigger: "change",  
      //   },  
      // ], 
      // delivery_select: [
      //   {
      //     required: true,
      //     message: "Delivery is required",
      //     trigger: "change",  
      //   },  
      // ], 
    });

    
   const currency = ref([]);
    const setCurrencyData = async () => {
        loading.value = true;
        try {
          await store.dispatch(Actions.CUST_PRODUCT_CURRENCY).then(({ data }) => {
          currency.value = data;
          console.log(currency.value)
          loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

   const incoterm = ref([]);
    const setIncotermData = async () => {
        loading.value = true;
        try {
          await store.dispatch(Actions.CUST_PRODUCT_INCOTERM).then(({ data }) => {
          incoterm.value = data;
          console.log(incoterm.value)
          loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const company = ref([]);
    const setCompanyData = async (data) => {
       // loading.value = true;
        var values = { 
            search_term : data }
        try {
          await store.dispatch(Actions.CUST_SEARCH_COMPANY_LIST, values).then(({ data }) => {
          company.value = data;
         // loading.value = false;
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const nob_data = ref([]);
    const setNobdata =async () => {
         try {
            var values = { 
            "company_id"  :  formData.value.company_select, 
            "page"  : 1, 
            "records_per_page" : 100 }
            await store.dispatch(Actions.CUST_GET_COMPANY_LIST, values).then(({ data }) => {
            formData.value.nob = data.company_nob_name
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }
    }

    const branch = ref([]);
    const setPlantData = async (compid) => {
      try {
            var values = { "company_id"  : compid, "page"  : 1, "records_per_page" : 10, "company_branch_id" : 0, }
            await store.dispatch(Actions.CUST_GET_PLANT_LIST, values).then(({ data }) => {
            branch.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }
    }

    const city = ref([]);
    const setCityData = async () => {
       try {
            var values = { 
            "company_id"  :  formData.value.company_select, 
            "company_branch_id" : formData.value.branch_select,
            "page"  : 1, 
            "records_per_page" : 100 }
            await store.dispatch(Actions.CUST_GET_BRANCH_LIST, values).then(({ data }) => {
               formData.value.city_select = data.city_name
               formData.value.state_select = data.state_name
               formData.value.pincode_select = data.pincode
           
            console.log("city")
            console.log(formData.value.nob)
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const contact = ref([]);
    const setContactData = async (compid) => {
        //loading.value = true;
        var values = { 
          "contact_id": 0, 
          "page": 1, 
          "search_term" : "",
          "records_per_page": 1, 
          "company_id" : compid, }
        try {
          await store.dispatch(Actions.CUST_LIST_CONTACT, values).then(({ data }) => {
          contact.value = data.result_list;
          console.log(contact.value)
          //loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    
    const contact_no_email = ref([]);
    const setContact_No_Email_Data = async () => {
       try {
            var values = { 
            "company_id"  :  formData.value.company_select, 
            "contact_id" : formData.value.contact_select,
            "search_term" : "",
            "page"  : 1, 
            "records_per_page" : 100 }
            await store.dispatch(Actions.CUST_LIST_CONTACT, values).then(({ data }) => {
               formData.value.contact_no = data.contact_mobile_no
               formData.value.email_select = data.contact_email
           
            console.log("city")
            console.log(formData.value.contact_no)
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

     const setPriceData = async (data) => {
      
      const db_data = {
        "price_template_id": 0,
        "template_name" : data.name,
        "category_id": data.category_select,
        "city_id": data.city_select,    
        "currency_id": data.value_select,
        "incoterm_id": data.incoterm_select,
        "delivery_term_id": data.delivery_select,
        "active":true,
  
        }
        console.log(db_data);
        await store.dispatch('Actions.CUST_ADD_PRICE_INDEX', db_data).then(({ data }) => {
          if (data){
        
            setTimeout(() => {
              //loading.value = false;
//
              Swal.fire({
                text: "Price Index added successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addPriceModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            //loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }

    const submit = async () => {
      loading.value = true;
      if (!formRef.value) {
        loading.value = false;
        return;
      }
      formRef.value.validate (async (valid) => {

        if (valid) {

          await setPriceData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }
      });    
    };

    return {      
      formData,      
      rules,
      submit,
      formRef,
      loading,
      addPriceModalRef,
      setCompanyData,
      setPlantData,
      setIncotermData,
      setCityData,
      setContact_No_Email_Data,
      setContactData,
      setCurrencyData,
      currency,
      contact,
      incoterm,
      branch,
      company,
      city,
      nob_data,
      setNobdata,
    };
  },
});
